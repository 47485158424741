/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Keyboard focus polyfill
import "focus-visible"
// IntersectionObserver polyfill
import "intersection-observer"

export const onClientEntry = () => {
  // Do nothing, for now
  // An export is required for the import(s) to work
}
