// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-disha-vs-oleads-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\blog\\disha-vs-oleads.js" /* webpackChunkName: "component---src-pages-blog-disha-vs-oleads-js" */),
  "component---src-pages-blog-index-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\blog\\index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-post-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\blog\\post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-blog-reasons-to-invest-in-ux-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\blog\\reasons-to-invest-in-ux.js" /* webpackChunkName: "component---src-pages-blog-reasons-to-invest-in-ux-js" */),
  "component---src-pages-blog-skills-for-ui-designers-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\blog\\skills-for-ui-designers.js" /* webpackChunkName: "component---src-pages-blog-skills-for-ui-designers-js" */),
  "component---src-pages-case-studies-develop-ondo-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\develop-ondo.js" /* webpackChunkName: "component---src-pages-case-studies-develop-ondo-js" */),
  "component---src-pages-case-studies-ereporter-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\ereporter.js" /* webpackChunkName: "component---src-pages-case-studies-ereporter-js" */),
  "component---src-pages-case-studies-lms-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\lms.js" /* webpackChunkName: "component---src-pages-case-studies-lms-js" */),
  "component---src-pages-case-studies-odirs-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\odirs.js" /* webpackChunkName: "component---src-pages-case-studies-odirs-js" */),
  "component---src-pages-case-studies-ondo-state-website-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\ondo-state-website.js" /* webpackChunkName: "component---src-pages-case-studies-ondo-state-website-js" */),
  "component---src-pages-case-studies-plaud-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\plaud.js" /* webpackChunkName: "component---src-pages-case-studies-plaud-js" */),
  "component---src-pages-case-studies-polleasy-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\polleasy.js" /* webpackChunkName: "component---src-pages-case-studies-polleasy-js" */),
  "component---src-pages-case-studies-prowoks-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\prowoks.js" /* webpackChunkName: "component---src-pages-case-studies-prowoks-js" */),
  "component---src-pages-case-studies-revenue-reporting-tool-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\revenue-reporting-tool.js" /* webpackChunkName: "component---src-pages-case-studies-revenue-reporting-tool-js" */),
  "component---src-pages-case-studies-sme-upturn-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\sme-upturn.js" /* webpackChunkName: "component---src-pages-case-studies-sme-upturn-js" */),
  "component---src-pages-case-studies-tech-4-dev-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\tech4dev.js" /* webpackChunkName: "component---src-pages-case-studies-tech-4-dev-js" */),
  "component---src-pages-case-studies-totalmetrics-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\case-studies\\totalmetrics.js" /* webpackChunkName: "component---src-pages-case-studies-totalmetrics-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-join-us-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-page-2-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-solutions-index-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\solutions\\index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-products-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\solutions\\products.js" /* webpackChunkName: "component---src-pages-solutions-products-js" */),
  "component---src-pages-solutions-projects-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\solutions\\projects.js" /* webpackChunkName: "component---src-pages-solutions-projects-js" */),
  "component---src-pages-solutions-websites-js": () => import("C:\\Users\\nifemiadeyemi\\Desktop\\Programming\\Projects\\Prunedge\\prunedge-website\\src\\pages\\solutions\\websites.js" /* webpackChunkName: "component---src-pages-solutions-websites-js" */)
}

